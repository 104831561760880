import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  OidcCommonValues,
  OidcConfigIds
} from '@common/models/user-management.model';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UmService } from 'app/auth/um-service/um.service';
import { InvalidLoginService } from 'app/invalid-login-dialog/invalid-login-service/invalid-login.service';
import { DatadogService } from 'app/shared/services/datadog-services/datadog.service';
import { UserFunctionsService } from 'app/user-management/user/user-functions-service/user-functions.service';
import { catchError, lastValueFrom, of } from 'rxjs';

/**
 * Component for handling user authorization/ auth callback.
 */
@Component({
  selector: 'app-authorize',
  templateUrl: './authorize.component.html'
})
export class AuthorizeComponent implements OnInit {
  constructor(
    private readonly umService: UmService,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly userFunctionsService: UserFunctionsService,
    private readonly datadogService: DatadogService,
    private readonly invalidLoginService: InvalidLoginService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {}

  /**
   * On component initialization, validates user time,
   * establishes the configuration ID and checks user authentication.
   */
  async ngOnInit(): Promise<void> {
    if (!(await this.umService.checkTime())) {
      this.establishConfigId();
      this.checkAuth();
    } else {
      await this.invalidLoginService.clockOutOfSync();
    }
  }

  /**
   * Establishes the configuration ID for the user identity provider.
   * It prioritizes the referrer parameter from the route's query parameters,
   * falls back to the currently set userIdp, or defaults to OidcConfigIds.XGS if neither is available.
   */
  establishConfigId(): void {
    const routeParams = this.route.snapshot.queryParams;
    this.umService.setUserIdp(
      routeParams?.referrer ?? this.umService.userIdp ?? OidcConfigIds.XGS
    );
  }

  /**
   * Checks the authentication status for multiple ID providers and handles user authorization.
   * If the user is authenticated, triggers user login and authorization processes.
   * Otherwise, initiates the authorization process for the specified ID provider.
   */
  checkAuth(): void {
    this.oidcSecurityService
      .checkAuthMultiple()
      .pipe(
        catchError(error => {
          this.datadogService.errorTracking(error, {
            message: 'Error checking authentication status'
          });
          return of([]);
        })
      )
      .subscribe(async oidcResponse => {
        const oidcConfig = oidcResponse.find(config => {
          return config.configId === this.umService.userIdp;
        });
        if (oidcConfig?.isAuthenticated) {
          this.userLogin();
          localStorage.setItem(OidcCommonValues.TAB_DETECTION, 'active');
          this.authorized();
        } else {
          this.oidcSecurityService.authorize(this.umService.userIdp);
        }
      });
  }

  /**
   * Navigates to a stored route from sessionStorage if available,
   * otherwise redirects to the landing page.
   */
  authorized(): void {
    const routeStorage = sessionStorage.getItem('route');
    if (routeStorage) {
      const routeObj = JSON.parse(routeStorage);
      this.router.navigate([routeObj.path], {
        queryParams: routeObj.queryParams
      });
    } else {
      this.router.navigate(['/landing']);
    }
  }

  /**
   * Used to track when last time a user logged in
   */
  async userLogin(): Promise<void> {
    if (this.umService.userIdp === OidcConfigIds.XGS) {
      try {
        const user = await lastValueFrom(
          this.oidcSecurityService.getUserData(this.umService.userIdp)
        );
        await this.userFunctionsService.trackUser(user, false, false, true);
      } catch (error) {
        this.datadogService.errorTracking(error, {
          message: 'Error updating user log'
        });
      }
    }
  }
}
